<template>
  <section class="ts-form-hero">
    <div class="ts-bg-gradient">
      <div class="ts-bg-1 ts-bg-radius ts-bg-radius--secondary z-minus-1"></div>
      <div class="ts-bg-3 ts-bg-radius ts-bg-radius--secondary z-minus-1"></div>
    </div>
    <div
      class="ts-bg-2 ts-bg-radius ts-bg-radius--primary-secondary--mix z-minus-1"
    ></div>
    <div class="ts-container-fluid container-fluid">
      <div class="ts-form-hero__content mx-auto">
        <img
          class="ts-hash"
          src="/images/hash-white.svg"
          alt="alternativeText"
        />
        <div v-show="elementVisible" class="ts-image-container">
          <div class="ts-image ts-image__1">
            <img src="/images/signup/burgur.png" alt="alternativeText" />
          </div>
          <div class="ts-image ts-image__2">
            <img src="/images/signup/donut.png" alt="alternativeText" />
          </div>
          <div class="ts-image ts-image__3">
            <div class="ts-inner-img">
              <img src="/images/signup/emoji2.png" alt="alternativeText" />
            </div>
          </div>
          <div class="ts-image ts-image__4">
            <img src="/images/signup/emoji.png" alt="alternativeText" />
          </div>
          <div class="ts-image ts-image__5">
            <div class="ts-rotate">
              <img src="/images/Home/heart.png" alt="alternativeText" />
            </div>
          </div>
        </div>
        <div class="row row-cols-lg-2 align-items-center gap-5 gap-lg-0">
          <div class="order-1 order-lg-0">
            <header
              class="ts-creator-form-hero__header ts-text-gray-2"
              v-if="$store.state.creatorSignupPage"
            >
              <img
                height="43"
                class="mb-03"
                :src="
                  $store.state.baseUrl +
                  '/uploads/quote_close_svgrepo_com_4c34c2e02c.svg'
                "
                alt="alternativeText"
              />
              <h1 class="ts-fs-2-b fw-bold mb-04 mb-lg-07">
                {{ $store.state.creatorSignupPage.mainDetails.title }}
              </h1>
              <p class="ts-fs-5 fw-light mb-05 mb-lg-07">
                {{ $store.state.creatorSignupPage.mainDetails.description }}
              </p>

              <div
                class="ts-text-gray-1 d-flex ts-rtl-justify-content-end ts-rtl-row-reverse align-items-center gap-3"
              >
                <div>
                  <img
                    width="70"
                    height="70"
                    class="rounded-circle"
                    :src="
                      $store.state.baseUrl +
                      $store.state.creatorSignupPage.mainDetails
                        .TestimonialContact.Image.data.attributes.url
                    "
                    alt="alternativeText"
                  />
                </div>
                <div>
                  <h5 class="ts-fs-7 ts-text-gray-1a fw-medium mb-1">
                    {{
                      $store.state.creatorSignupPage.mainDetails
                        .TestimonialContact.title
                    }}
                  </h5>
                  <div
                    class="ts-social-links d-flex gap-2 gap-sm-3 justify-content-start"
                  >
                    <a
                      class="ts-text-gray-4"
                      :href="
                        $store.state.creatorSignupPage.mainDetails
                          .TestimonialContact.instagramLink
                      "
                      ><i class="bi bi-instagram"></i></a
                    ><a
                      class="ts-text-gray-4"
                      :href="
                        $store.state.creatorSignupPage.mainDetails
                          .TestimonialContact.twitchLink
                      "
                      ><i class="bi bi-twitch"></i></a
                    ><a
                      class="ts-text-gray-4"
                      :href="
                        $store.state.creatorSignupPage.mainDetails
                          .TestimonialContact.twitterLink
                      "
                      ><i class="bi bi-twitter-x"></i
                    ></a>
                  </div>
                </div>
              </div>
            </header>
          </div>
          <div class="order-0 order-lg-1">
            <div class="ts-contact">
              <div class="ts-contact__content">
                <div class="ts-contact-form-container ts-shadow-1">
                  <div class="mb-3 position-relative">
                    <button
                      class="ts-btn ts-btn--sm border-0 ts-btn--back ts-fs-7-ii position-absolute backarrow-button"
                      @click="handleSlideBack"
                      v-if="numberVerificationStatus"
                    >
                      <Icon name="chevron-left" />
                    </button>
                    <div
                      class="new-form-headers-container"
                      :class="{
                        'hide-forverification':
                          numberVerificationStatus || congratulationsStatus,
                      }"
                    >
                      <h1 class="ts-new-form-header">
                        {{
                          step === 3
                            ? "Add your instagram"
                            : instaTikTokVerificationStatus
                            ? "Almost there!"
                            : "Sign up"
                        }}
                      </h1>
                      <p class="ts-new-form-paragraph">
                        {{
                          step === 3
                            ? "You can link more social accounts to your alist profile later"
                            : instaTikTokVerificationStatus
                            ? "Verify the ownership of your social media accounts"
                            : "Please fill in your details"
                        }}
                      </p>
                    </div>

                    <div class="main-form-container">
                      <KeepAlive
                        ><Form1Ver2 v-if="step === 1" ref="creatorSignupform1"
                      /></KeepAlive>
                      <KeepAlive
                        ><Form2Ver2
                          v-if="step === 2"
                          ref="creatorSignupform2"
                          :creator-id="creatorId"
                      /></KeepAlive>

                      <Form3Ver2
                        v-if="step === 3"
                        ref="creatorSignupform3"
                        :creator-id="creatorId"
                      />

                      <numberVerification
                        v-if="numberVerificationStatus"
                        ref="numberVerification"
                        :creator-id="creatorId"
                        @otpVerified="handleOtpVerified"
                      />
                      <instaTikTokVerification
                        v-if="instaTikTokVerificationStatus"
                        :result="result"
                      />

                      <congratulationsView
                        v-if="congratulationsStatus"
                        :heading="
                          $store.state.creatorSignupPage.NewCForm1
                            .Congratulations.title
                        "
                        :message="
                          $store.state.creatorSignupPage.NewCForm1
                            .Congratulations.paragraph1
                        "
                        :notification="
                          $store.state.creatorSignupPage.NewCForm1
                            .Congratulations.paragraph2
                        "
                      />

                      <StepDots
                        v-if="
                          !numberVerificationStatus &&
                          !instaTikTokVerificationStatus &&
                          !congratulationsStatus
                        "
                        :totalSteps="3"
                        :activeStep="step - 1"
                        @navigate="handleStepNavigation"
                      />

                      <div v-if="$store.state.creatorSignupPage">
                        <button
                          @click="handleFormSubmit"
                          class="newform-submit-button"
                          :class="{
                            'new-form-secondary-button':
                              instaTikTokVerificationStatus,
                          }"
                          :disabled="isProcessing"
                        >
                          {{ btnText }}
                        </button>
                      </div>
                      <h3
                        class="newform-linktext"
                        :class="{
                          'visually-hidden-congrats': congratulationsStatus,
                        }"
                        v-if="$store.state.creatorSignupPage"
                      >
                        {{
                          $store.state.creatorSignupPage.PageLinks
                            .alreadyHaveAnAccount.text
                        }}
                        <CustomLinkLogin
                          class="newform-link"
                          text="
                            Log in
                          "
                          :toSrc="
                            $store.state.creatorSignupPage.PageLinks
                              .alreadyHaveAnAccount.linkTo
                          "
                        />
                      </h3>
                    </div>
                  </div>
                </div>

                <h3
                  class="ts-fs-7-ii text-center ts-already-signup-2 fw-normal"
                  v-if="$store.state.creatorSignupPage"
                >
                  {{
                    $store.state.creatorSignupPage.PageLinks.areYouABrand.text
                  }}

                  <router-link
                    class="fw-bold ts-text-primary-1 text-decoration-none fw-bold"
                    :to="
                      $store.state.creatorSignupPage.PageLinks.areYouABrand
                        .linkTo
                    "
                  >
                    {{
                      $store.state.creatorSignupPage.PageLinks.areYouABrand
                        .linkText
                    }}
                  </router-link>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import CustomLinkLogin from "../Helpers/CustomLinkLogin.vue";

import "swiper/css";

import "swiper/css/pagination";

import Form1Ver2 from "@/components/Form/creator/newFormVer2/Form1Ver2.vue";
import Form2Ver2 from "@/components/Form/creator/newFormVer2/Form2Ver2.vue";
import Form3Ver2 from "@/components/Form/creator/newFormVer2/Form3Ver2.vue";
import numberVerification from "./creator/newFormVer2/numberVerification.vue";
import instaTikTokVerification from "./creator/newFormVer2/instaTikTokVerification.vue";

import StepDots from "../Common/StepDots.vue";
import congratulationsView from "./creator/newFormVer2/congratulationsView.vue";
import Icon from "../../assets/icons/index.vue";
export default {
  name: "CreatorSignupHero",
  components: {
    Form1Ver2,
    Form2Ver2,
    Form3Ver2,
    CustomLinkLogin,
    numberVerification,
    instaTikTokVerification,
    congratulationsView,
    StepDots,
    Icon,
  },

  computed: {
    activeLanguage() {
      return this.$store.getters.activeLanguage;
    },
    markdownRules() {
      return {
        link_open(tokens, idx, options, env, self) {
          const aToken = tokens[idx];
          aToken.attrPush(["target", "_blank"]);
          return self.renderToken(tokens, idx, options);
        },
      };
    },
  },

  watch: {
    "addSocialMediaData.username"() {
      if (this.addSocialMediaData.hasError) {
        this.addSocialMediaData.hasError = false;
      }
    },
    activeLanguage(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$store.dispatch("getErrorMessages");
      }
    },
  },

  data() {
    return {
      step: 1,
      numberVerificationStatus: false,
      instaTikTokVerificationStatus: false,
      congratulationsStatus: false,
      btnText: "Next",
      elementVisible: false,
      creatorId: null,
      result: null,
      isProcessing: false,
    };
  },
  created() {
    setTimeout(() => (this.elementVisible = true), 500);
  },
  beforeCreate() {
    this.$store.dispatch("getNationalities");
    this.$store.dispatch("getCountries");
    this.$store.dispatch("getErrorMessages");
  },
  methods: {
    handleCreatorIdGenerated(creatorId) {
      this.creatorId = creatorId;
      this.step = -1;
      this.numberVerificationStatus = true;
      this.btnText = "Verify";
    },
    handleStepNavigation(step) {
      this.step = step; // Navigate to the selected step
    },
    handleSlideBack() {
      // Navigate back to step 1
      this.step = 1;
      this.numberVerificationStatus = false; // Disable number verification status if needed
    },

    async handleOtpVerified(event) {
      if (event.status) {
        this.numberVerificationStatus = false;
        this.step = 2;
        this.btnText = "Next";
      } else {
        this.numberVerificationStatus = true;
      }
    },
    /* async handleFormSubmit() {
      if (this.numberVerificationStatus) {
        // Simulate OTP verification success
        console.log("Simulated: OTP Verified");
        this.numberVerificationStatus = false;
        this.step = 2; // Proceed to Step 2 after OTP verification
        this.btnText = "Next";
        return;
      }

      if (this.congratulationsStatus) {
        // Simulate redirection logic
        const redirectLink =
          this.$store.state.creatorSignupPage.PageLinks.alreadyHaveAnAccount
            .linkTo;
        if (redirectLink) {
          console.log("Simulated: Opening link", redirectLink);
          window.open(redirectLink, "_blank"); // Open in a new tab for testing
          return;
        }
      }

      switch (this.step) {
        case 1: {
          // Simulate Step 1 submission success
          console.log("Simulated: Step 1 submitted");
          this.creatorId = "testCreatorId"; // Dummy creator ID for testing
          this.step = -1; // Go to OTP verification
          this.numberVerificationStatus = true;
          this.btnText = "Verify";
          break;
        }
        case 2: {
          // Simulate Step 2 submission success
          console.log("Simulated: Step 2 submitted");
          this.step = 3; // Proceed to Step 3
          this.btnText = "Next";
          break;
        }
        case 3: {
          // Simulate Step 3 submission success
          console.log("Simulated: Step 3 submitted");
          this.result = {
            status: true,
            instagramPin: "123456", // Dummy Instagram pin
            tiktokFilled: true, // Dummy TikTok filled status
          }; // Dummy data for testing
          this.step = -2; // Move to InstaTikTokVerification step
          this.instaTikTokVerificationStatus = true;
          this.btnText = "Verify";
          break;
        }
        case -2: {
          // Simulate InstaTikTokVerification completion
          console.log("Simulated: InstaTikTokVerification completed");
          this.instaTikTokVerificationStatus = false;
          this.congratulationsStatus = true;
          this.btnText = "Log in";
          break;
        }
      }
    },*/

    async handleFormSubmit() {
      if (this.isProcessing) return; // Prevent further clicks while processing

      this.isProcessing = true; // Disable the button

      try {
        if (this.numberVerificationStatus) {
          await this.$refs.numberVerification.submitOtp();
          return;
        }

        if (this.congratulationsStatus) {
          const redirectLink =
            this.$store.state.creatorSignupPage.PageLinks.alreadyHaveAnAccount
              .linkTo;
          if (redirectLink) {
            window.open(redirectLink, "_blank"); // Open the link in a new tab
            return; // Stop further execution
          } else {
            return;
          }
        }

        switch (this.step) {
          case 1: {
            const creatorId = await this.$refs.creatorSignupform1.submitFrom1();
            if (creatorId) {
              this.creatorId = creatorId;
              this.step = -1; // Go to OTP verification
              this.numberVerificationStatus = true;
              this.btnText = "Verify";
            }
            break;
          }
          case 2: {
            const result = await this.$refs.creatorSignupform2.submitFrom2();
            if (result && result.status) {
              this.step = 3; // Proceed to Step 3
              this.btnText = "Next";
            }
            break;
          }
          case 3: {
            try {
              const result = await this.$refs.creatorSignupform3.submitFrom3();
              if (result && result.status) {
                this.result = result;
                this.step = -2; // Move to InstaTikTokVerification step
                this.instaTikTokVerificationStatus = true;
                this.btnText = "Verify";
              }
            } catch (error) {
              console.error("Error during Step 3 submission:", error);
            }
            break;
          }
          case -2: {
            // Finalize InstaTikTokVerification step
            this.instaTikTokVerificationStatus = false;
            this.congratulationsStatus = true;
            this.btnText = "Log in";
            break;
          }
        }
      } catch (error) {
        console.error("Error during form submission:", error);
      } finally {
        this.isProcessing = false; // Re-enable the button after processing
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.ts-btn--back {
  z-index: 20;
  background-color: transparent;
}

// .ts-creator-swiper {
//   min-height: 433px;
// }
.ts-congratulation {
  min-height: 384px;
  max-width: 393px;
  margin: auto;

  @media (min-width: 992px) {
    min-height: 442px;
  }
}

.ts-bg-gradient {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -7;

  @media (max-width: 1500px) {
    overflow: hidden;
  }
}

.ts-social-links {
  font-size: clamp(21px, 3.5vw, 23px);

  svg {
    width: 20px;
  }
}

.ts-social-media-handle {
  border-radius: 1rem;

  &--lg {
    width: 96px;
    height: 96px;
  }

  #instagram {
    width: 55px !important;
    height: 55px !important;
  }
}

.ts-copy-code {
  background-color: #f4f4f4;
  border: 1px solid #f4f4f4;
  padding: 11px 25px 11px 35px;
  border-radius: 8px;
  gap: 1rem;
}

.ts-add-social-media-modal-content {
  max-width: 405px;
  padding: 57px 36px;
  border-radius: 34px;
}

@media (max-width: 475px) {
  .ts-creator-form-hero__header {
    max-width: 338px;
    margin: auto;
  }
}

.ts-form-hero {
  // padding-block: 14rem 6rem;
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  isolation: isolate;
  padding-block: 6.5625rem 3.5rem;
  overflow: hidden;
  min-height: 100vh;

  @media (min-width: 992px) {
    padding-block: 11rem 6rem;
  }

  &__content {
    max-width: 1224px;
    margin: auto;

    @media (min-width: 992px) {
      position: relative;
    }
  }

  .ts-bg-1 {
    position: absolute;
    bottom: 30%;
    left: 0;
    transform: translate(-26%, -119%);
    width: clamp(800px, 140vw, 1302px) !important;
    z-index: -3;

    @media (max-width: 991px) {
      transform: translate(-38%, 17%);
    }
  }

  .ts-bg-2 {
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translate(-62%, 61%);
    width: clamp(800px, 120vw, 1060px) !important;
    z-index: -3;

    @media (max-width: 991px) {
      transform: translate(-79%, 61%);
    }
  }

  .ts-bg-3 {
    position: absolute;
    bottom: 30%;
    left: 0;
    transform: translate(-40%, -64%);
    width: clamp(800px, 140vw, 1302px) !important;
    z-index: -2;

    @media (max-width: 991px) {
      transform: translate(-38%, 17%);
    }
  }

  .ts-hash {
    position: absolute;
    left: 0%;
    top: 12%;
    width: clamp(320px, 40vw, 500px);
    z-index: -2;
    transform: translateX(-50%);
    opacity: 85%;

    @media (max-width: 991px) {
      top: auto;
      bottom: -29px;
      left: 3%;
      opacity: 70%;
    }
  }
}

.ts-contact {
  margin: 0 !important;
}

.ts-image-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -2;

  @media (max-width: 991px) {
    overflow: hidden;
  }
}

.ts-image {
  img {
    position: absolute !important;
  }

  &__1 {
    img {
      width: clamp(80px, 8vw, 120px) !important;
      animation: floating 2.5s ease-in-out infinite;
    }
  }

  &__2 {
    img {
      width: clamp(90px, 12vw, 140px) !important;
      animation: floating 3s ease-in-out infinite;
    }

    .ts-rotate {
      transform: rotate(30deg);
    }
  }

  &__3 {
    img {
      width: clamp(80px, 10vw, 120px) !important;
      animation: floating 3.5s ease-in-out infinite;
    }
  }

  &__4 {
    img {
      width: clamp(80px, 11vw, 110px) !important;
      animation: floating 4s ease-in-out infinite;
    }
  }

  &__5 {
    img {
      width: clamp(80px, 12vw, 140px) !important;
      animation: floating 4s ease-in-out infinite;
    }

    .ts-rotate {
      transform: rotate(-30deg);
    }
  }

  &__1 {
    img {
      left: 43%;
      top: 10%;
    }
  }

  &__2 {
    img {
      top: 87%;
      right: 0;

      @media (min-width: 1300px) {
        top: 82%;
        right: -7%;
      }
    }
  }

  &__3 {
    img {
      top: -7%;
      right: 0;

      @media (min-width: 1300px) {
        top: 4%;
        right: -6%;
      }
    }
  }

  &__4 {
    img {
      right: 52% !important;
      top: 72%;
      transform: translate(100%, -100%);
    }
  }

  &__5 {
    img {
      top: 100%;
      left: 0%;
      transform: translate(-260%, 181%) !important;
    }
  }

  @media (max-width: 1400px) and (min-width: 1201px) {
    &__5 {
      img {
        transform: translate(-193%, 188%) !important;
      }
    }
  }

  @media (max-width: 1200px) and (min-width: 992px) {
    &__5 {
      img {
        transform: translate(-206%, 210%) !important;
      }
    }
  }

  @media (max-width: 991px) {
    &__2,
    &__3,
    &__4 {
      display: none;
    }

    &__1 {
      img {
        left: auto !important;
        top: 66%;
        right: 16px;
        transform: rotate(45deg) !important;
      }
    }

    &__5 {
      position: absolute;
      top: 87%;
      left: auto;
      right: 61px;

      .ts-rotate {
        transform: rotate(45deg);
      }

      img {
        left: 80%;
        top: 65%;
        transform: translate(0, 0) !important;
      }
    }
  }
}

@keyframes floating {
  0% {
    transform: rotate(-5deg) translatey(0px);
  }

  50% {
    transform: rotate(-3deg) translatey(7px);
  }

  100% {
    transform: rotate(-5deg) translatey(0px);
  }
}

/* new form styles */
.ts-contact {
  font-family: "Poppins";
  text-align: center;
}

.ts-contact-form-container {
  /* Set fixed height to match your tallest form */
  overflow: hidden; /* Hide overflow if content exceeds */
  min-height: 650px;
  max-height: 650px;
}
.new-form-headers-container {
  max-height: 100px;
  min-height: 100px;
}
.main-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  min-height: 480px;
  max-height: 480px;
  width: 100%;
}

.main-form-container > * {
  width: 100%;
}

.ts-new-form-header {
  font-weight: 600;
  font-size: 28px;
  line-height: 1.25;
  color: #00a4b6;
  margin-bottom: 18px;
}
.ts-new-form-paragraph {
  font-size: 19px;
  line-height: 1.52631578947;
  color: #4d4d4d;
}

.hide-forverification {
  opacity: 0; /* Make the element fully transparent */
  visibility: hidden; /* Make the element non-interactive */
}
.newform-submit-button {
  border: 1.5px solid transparent;
  margin: 0;
  padding: 0;
  overflow: visible;

  background: transparent;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;
  background-color: #00a4b6;
  color: #fff;
  font-size: clamp(1rem, 0.5rem + 1.5vw, 1.125rem);
  font-weight: 600;
  font-family: inherit;
  width: 100%;
  padding-block: 13.5px;
  border-radius: 18px;
  margin-bottom: 1rem;
  box-sizing: border-box;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}
.newform-submit-button:hover {
  background-color: #fff; /* White background on hover */
  color: #00a4b6; /* Font color remains the same */

  border-color: #00a4b6; /* Border color remains the same */
}
.newform-linktext {
  font-size: clamp(0.9375rem, 0.5rem + 1.5vw, 1.125rem);
  color: #4d4d4d;
}

.new-form-secondary-button {
  background-color: #686868;
}

.new-form-secondary-button:hover {
  background-color: #fff; /* White background on hover */
  color: #686868; /* Font color remains the same */
  border-color: #686868;
}

.visually-hidden-congrats {
  visibility: hidden;
  opacity: 0;
  pointer-events: none; /* Prevent interaction */
  transition: opacity 0.3s ease-in-out; /* Optional: Smooth transition for fade effect */
}
@media (max-width: 500px) {
  .ts-new-form-header {
    font-size: 25px;
  }
}
@media (max-width: 400px) {
  .ts-new-form-header {
    font-size: 20px;
  }
  .ts-new-form-paragraph {
    font-size: 17px;
  }
}

.backarrow-button {
  left: -40px;
  top: -30px;
}
</style>
