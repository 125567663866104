<template>
  <section>
    <div class="newform-OTP-area">
      <div
        class="newform-OTP-number"
        @click="copyToClipboard(result.instagramPin)"
        :title="'Click to copy ' + result.instagramPin"
      >
        {{ result.instagramPin }}
      </div>
      <p>
        {{
          $store.state.creatorSignupPage?.NewCForm1?.FormStep1
            ?.instaVerification1
        }}
        <a
          :href="$store.state.creatorSignupPage?.NewCForm1?.links?.alist"
          target="_blank"
          rel="noopener noreferrer"
          class="color-primary"
        >
          {{
            $store.state.creatorSignupPage?.NewCForm1?.FormStep1
              ?.instaVerification2
          }}
        </a>
        {{
          $store.state.creatorSignupPage?.NewCForm1?.FormStep1
            ?.instaVerification3
        }}
      </p>
    </div>
    <button
      class="primary-social-button"
      v-if="!result.tiktokFilled"
      @click="openLink('instagram')"
    >
      Open Instagram
    </button>
    <!-- Render both buttons only if isTiktokExist is true -->
    <div class="buttons-container" v-if="result.tiktokFilled">
      <div class="social-button" @click="openLink('instagram')">
        <img
          src="/images/new creators form/buttonsicons/instagram.svg"
          alt="Instagram Icon"
          class="instagram-icon"
        />
        <button>Instagram</button>
      </div>
      <div class="social-button" @click="openLink('tiktok')">
        <img
          src="/images/new creators form/buttonsicons/tik-tok.svg"
          alt="TikTok Icon"
        />
        <button>TikTok</button>
      </div>
    </div>
  </section>
</template>

<script>
import { toast } from "vue3-toastify"; // Use your toast library

export default {
  props: {
    result: {
      type: Object,
      required: true,
    },
  },
  methods: {
    openLink(platform) {
      let url = "";
      if (platform === "instagram") {
        url =
          this.$store.state.creatorSignupPage?.NewCForm1?.socials?.instagram; // Replace with your Instagram link
      } else if (platform === "tiktok") {
        url = this.$store.state.creatorSignupPage?.NewCForm1?.socials?.tiktok; // Replace with your TikTok link
      }
      if (url) {
        window.open(url, "_blank"); // Open the URL in a new tab
      }
    },
    async copyToClipboard(text) {
      if (navigator.clipboard && navigator.clipboard.writeText) {
        try {
          await navigator.clipboard.writeText(text);
          toast.success("Code copied to clipboard!", {
            toastId: "Code copied to clipboard!",
          });
        } catch (err) {
          toast.error("Failed to copy the code. Please try again.", {
            toastId: "Failed to copy the code. Please try again.",
          });
          // console.error("Clipboard copy failed:", err);
        }
      } else {
        // Fallback for unsupported environments
        try {
          const textArea = document.createElement("textarea");
          textArea.value = text;
          textArea.style.position = "fixed"; // Prevent scrolling to bottom
          textArea.style.opacity = "0"; // Make it invisible
          document.body.appendChild(textArea);
          textArea.select();
          document.execCommand("copy");
          document.body.removeChild(textArea);
          toast.success("Code copied to clipboard!");
        } catch (err) {
          toast.error("Failed to copy the code. Please try again.", {
            toastId: "Failed to copy the code. Please try again.",
          });
          // console.error("Fallback clipboard copy failed:", err);
        }
      }
    },
  },
};
</script>

<style scoped>
button {
  border: 1.5px solid transparent;
  background-color: #00a4b6;
  color: #fff;
  font-size: clamp(1rem, 0.5rem + 1.5vw, 1.125rem);
  font-weight: 600;
  font-family: inherit;
  padding: 13.5px;
  box-sizing: border-box;
  border-radius: 18px;
}

.primary-social-button {
  width: 100%;
  margin-bottom: 1rem;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}
.primary-social-button:hover {
  background-color: #fff; /* White background on hover */
  color: #00a4b6; /* Font color remains the same */

  border-color: #00a4b6; /* Border color remains the same */
}

.buttons-container {
  display: flex;
  gap: 18px;
  margin-bottom: 1rem;
}

.social-button {
  display: flex;
  align-items: center;
  justify-content: center;
  /* gap: 11px;*/
  background-color: #00a4b6;
  border-radius: 18px;
  flex: 1;
  cursor: pointer;
}

.newform-OTP-area {
  background-color: #f9f9f9;
  border-radius: 18px;
  padding: 35px 23px 30px 23px;
  margin-bottom: 60px;
}

.newform-OTP-number {
  color: #00a4b6;
  font-weight: bold;
  font-size: 24px;
  letter-spacing: 0.5rem;
  background-color: #fff;
  width: fit-content;
  margin: 0 auto;
  padding: 8.5px 24px;
  border-radius: 18px;
  margin-bottom: 19px;
}

p {
  font-size: 15px;
  color: #606060;
  padding-inline: 18px;
}

.color-primary {
  color: #00a4b6;
  font-weight: bold;
}

@media (max-width: 450px) {
  .instagram-icon {
    margin-left: 13px;
  }
}
</style>
