<template>
  <section>
    <img
      src="/images/new creators form/email verificaton.svg"
      alt="Email Verification"
    />
    <h1 class="verification-title">
      {{ $store.state.creatorSignupPage?.NewCForm1?.FormStep1?.emailOtpTitle }}
    </h1>
    <p class="verification-paragraph">
      {{
        $store.state.creatorSignupPage?.NewCForm1?.FormStep1?.emailOtpParagraph
      }}
    </p>
    <p class="verification-paragraph">
      {{
        $store.state.creatorSignupPage?.NewCForm1?.FormStep1?.emailOtpParagraph2
      }}
    </p>
    <div class="newform-otp-input">
      <input
        v-for="(value, index) in otp"
        :key="index"
        ref="otpInput"
        type="text"
        inputmode="numeric"
        pattern="\d+"
        maxlength="1"
        class="newform-otp-box"
        v-model="otp[index]"
        @input="handleInput(index)"
        @paste="handlePaste"
      />
    </div>
  </section>
</template>

<script>
import { axiosAPI } from "../../../../axiosClient"; // Adjust path as per your project
import { toast } from "vue3-toastify"; // Import the toast module

export default {
  name: "NumberVerification",
  props: {
    creatorId: {
      type: Number,
      required: true, // Expecting the creatorId from the previous step
    },
  },
  data() {
    return {
      otp: ["", "", "", ""], // Holds the values of the OTP inputs
    };
  },
  methods: {
    handlePaste(event) {
      const pasteData = event.clipboardData.getData("text"); // Get the pasted text
      if (/^\d{4}$/.test(pasteData)) {
        // Ensure the pasted text is a 4-digit number
        this.otp = pasteData.split(""); // Split the string into individual digits
        const inputs = this.$refs.otpInput; // Get the input fields
        inputs[3]?.focus(); // Automatically focus the last input
      }
    },
    handleInput(index) {
      const inputs = this.$refs.otpInput; // Access the array of input refs
      // Automatically move to the next input when the current one is filled
      if (this.otp[index].length === 1 && index < 3) {
        inputs[index + 1]?.focus();
      } else if (this.otp[index].length === 0 && index > 0) {
        // Move back if the current input is cleared
        inputs[index - 1]?.focus();
      }
    },
    async submitOtp() {
      const otpCode = this.otp.join(""); // Combine OTP digits into a single string
      if (otpCode.length !== 4) {
        toast.error("Please enter a valid 4-digit OTP.", {
          toastId: "Please enter a valid 4-digit OTP.",
        });
        return;
      }

      try {
        const response = await axiosAPI.post("/v1/creator/verify-otp", {
          creator_id: this.creatorId,
          otp: otpCode,
        });

        if (response.data?.status) {
          // Emitting OTP verification success
          this.$emit("otpVerified", { status: response.data.status });
        } else {
          // Emitting OTP verification failure
          this.$emit("otpVerified", { status: response.data.status });
          toast.error(response.data?.message || "OTP Verification Failed", {
            toastId: response.data?.message || "OTP Verification Failed",
          }); // Using toast here as well
        }
      } catch (error) {
        //console.error("OTP Verification Error:", error);
        this.$emit("otpVerified", { status: false });
        toast.error("An error occurred while verifying OTP.", {
          toastId: "An error occurred while verifying OTP.",
        }); // Using toast here as well
      }
    },
  },
};
</script>

<style scoped>
.verification-title {
  font-size: 28px;
  font-weight: 600;
  margin-top: 21px;
  line-height: 1.25;
  color: #403e3e;
  margin-bottom: 36px;
}
p {
  margin: 0;
}
.verification-paragraph {
  font-size: 19px;

  line-height: 1.52631578947;
  color: #4d4d4d;
}

.newform-otp-input {
  display: flex;
  justify-content: center; /* Distribute space between rectangles */
  align-items: center; /* Center align the rectangles vertically */
  margin-top: 43px; /* Space above the OTP input */
  gap: 30px;
  margin-bottom: 80px;
}

.newform-otp-box {
  width: 60px; /* Width of each rectangle */
  height: 54px; /* Height of each rectangle */
  border-radius: 18px; /* Rounded corners */
  background-color: #f0f0f0; /* Background color */
  border: 1px solid #ccc; /* Optional border for visibility */
  text-align: center; /* Center the text */
  font-size: 24px; /* Font size for digits */
  outline: none; /* Remove outline */
  transition: border-color 0.3s; /* Transition for border color change */
}

/* Optional: Focus effect for better UX */
.newform-otp-box:focus {
  border-color: #00a4b6; /* Change border color on focus */
  box-shadow: 0 0 5px rgba(0, 164, 182, 0.5); /* Optional shadow effect */
}
@media (max-width: 500px) {
  .verification-paragraph {
    font-size: 16px;
  }
  .newform-otp-input {
    gap: 5vw;
  }
}
@media (max-width: 360px) {
  .verification-paragraph {
    font-size: 15px;
  }
  .newform-otp-input {
    gap: 2vw;
  }
  .newform-otp-box {
    transform: scale(0.9);
  }
}
</style>
