<template>
  <div class="new-form-step-content">
    <form @submit.prevent="submitFrom3">
      <!-- Instagram Input -->
      <div class="input-icon-container">
        <div class="input-icon">
          <img src="/images/new creators form/instagram.svg" alt="" />
        </div>
        <input
          type="text"
          class="new-form-control new-form-control-includes-icon"
          id="instagram_url"
          name="instagram_url"
          :placeholder="
            $store.state.creatorSignupPage?.NewCForm1?.FormStep1
              ?.instagramPlaceholder
          "
          v-model="FormData.instagram_url"
          :class="{ 'is-invalid': FromErrorFlags.instagram_url }"
          @input="clearError('instagram_url')"
        />
      </div>
      <label for="instagram_url" class="instagram_url-label">
        {{
          $store.state.creatorSignupPage?.NewCForm1?.FormStep1
            ?.instagramMandatory
        }}
      </label>

      <!-- TikTok Input -->
      <div class="input-icon-container margin-bottom-16">
        <div class="input-icon">
          <img src="/images/new creators form/tik-tok.svg" alt="" />
        </div>
        <input
          type="text"
          class="new-form-control new-form-control-includes-icon"
          id="tiktok"
          name="tiktok"
          :placeholder="
            $store.state.creatorSignupPage?.NewCForm1?.FormStep1
              ?.tiktokPlaceholder
          "
          v-model="FormData.tiktok"
          :class="{ 'is-invalid': FromErrorFlags.tiktok }"
          @input="clearError('tiktok')"
        />
      </div>

      <!-- Terms Checkbox -->
      <div
        class="terms-container d-flex w-100 gap-16 margin-left-08 checkbox-label-container"
      >
        <div class="checkbox-container">
          <input
            type="checkbox"
            id="custom-checkbox"
            class="custom-checkbox"
            v-model="acceptedTerms"
          />
          <label for="custom-checkbox" class="checkbox-label"></label>
        </div>
        <label for="custom-checkbox" class="terms-label">
          {{
            $store.state.creatorSignupPage?.NewCForm1?.FormStep1
              ?.acceptTermsPrivacy
          }}
          <a
            :href="$store.state.creatorSignupPage?.NewCForm1?.links?.terms"
            target="_blank"
            rel="noopener noreferrer"
            class="new-form-color-primary"
          >
            {{ $store.state.creatorSignupPage?.NewCForm1?.FormStep1?.Terms }}
          </a>

          &amp;
          <a
            :href="$store.state.creatorSignupPage?.NewCForm1?.links?.privacy"
            target="_blank"
            rel="noopener noreferrer"
            class="new-form-color-primary"
          >
            {{
              $store.state.creatorSignupPage?.NewCForm1?.FormStep1?.Privacy
            }}</a
          >
        </label>
      </div>
    </form>
  </div>
</template>

<script>
import { axiosAPI } from "../../../../axiosClient";
import { toast } from "vue3-toastify";

export default {
  name: "CreatorForm3Ver2",
  props: {
    creatorId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      FormData: {
        instagram_url: "",
        tiktok: "",
      },
      FromErrorFlags: {
        instagram_url: false,
        tiktok: false,
      },
      acceptedTerms: false,
      creator_id: this.creatorId,
    };
  },
  methods: {
    validateForm() {
      let valid = true;
      this.resetErrorFlags();

      const instagramUsernamePattern = /^[a-zA-Z0-9._]{1,30}$/;
      if (!this.FormData.instagram_url) {
        this.FromErrorFlags.instagram_url = true;
        toast.error("Instagram username is required.", {
          toastId: "Instagram username is required.",
        });
        valid = false;
      } else if (!instagramUsernamePattern.test(this.FormData.instagram_url)) {
        this.FromErrorFlags.instagram_url = true;
        toast.error(
          "Instagram username is invalid. Only letters, numbers, periods, and underscores are allowed.",
          {
            toastId:
              "Instagram username is invalid. Only letters, numbers, periods, and underscores are allowed.",
          }
        );
        valid = false;
      }

      if (this.FormData.tiktok && /[^A-Za-z0-9._]/.test(this.FormData.tiktok)) {
        this.FromErrorFlags.tiktok = true;
        toast.error("TikTok username contains invalid characters.", {
          toastId: "TikTok username contains invalid characters.",
        });
        valid = false;
      }

      if (!this.acceptedTerms) {
        toast.error(
          "You must accept the terms and privacy policy to proceed.",
          {
            toastId: "You must accept the terms and privacy policy to proceed.",
          }
        );
        valid = false;
      }

      return valid;
    },

    resetErrorFlags() {
      for (const field in this.FromErrorFlags) {
        this.FromErrorFlags[field] = false;
      }
    },

    clearError(field) {
      if (this.FromErrorFlags[field]) {
        this.FromErrorFlags[field] = false;
      }
    },

    async submitFrom3() {
      this.resetErrorFlags();
      const isValid = this.validateForm();
      if (!isValid) return false;

      // Check if TikTok field is empty or not, and emit the status
      const isTikTokFilled = !!this.FormData.tiktok.trim();
      //this.$emit("tiktokFilled", isTikTokFilled); // Emit the value to the parent
      console.log("Emitting TikTok Filled Status:", isTikTokFilled);

      const formDataToSend = {
        instagram_url: this.FormData.instagram_url,
        tiktok: this.FormData.tiktok,
        creator_id: this.creator_id,
        step: 3,
      };

      try {
        const response = await axiosAPI.post(
          "/v1/creator/signup",
          formDataToSend
        );

        if (response.data && response.data.status) {
          this.$emit("submitForm", {
            status: response.data.status,
            instagramPin: response.data.data.instagramPin,
            tiktokFilled: isTikTokFilled, // Emit TikTok status within the same event
          });

          return {
            status: response.data.status,
            instagramPin: response.data.data.instagramPin,
            tiktokFilled: isTikTokFilled, // Return TikTok status as part of the result
          };
        } else {
          toast.error(response.data.message || "Submission failed.", {
            toastId: response.data.message || "Submission failed3.",
          });
          return { status: response.data.status };
        }
      } catch (error) {
        toast.error("An unexpected error occurred. Please try again.", {
          toastId: "An unexpected error occurred. Please try again.3",
        });
        return { status: false };
      }
    },
  },
};
</script>

<style scoped>
.gap-16 {
  gap: 1rem;
}
.margin-left-08 {
  margin-left: 0.5rem;
}
.instagram_url-label {
  display: block;
  text-align: left;
  margin: 7px 0 24px 16px;
  font-size: 15px;
  color: #00a4b6;
}
.new-form-color-primary {
  color: #00a4b6;
}
.terms-label {
  font-size: 15px;
}
.terms-container {
  margin-top: 69px;
  margin-bottom: -10px;
}
.is-invalid {
  outline: 1px solid red;
}
</style>
