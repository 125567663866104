<template>
  <div v-if="$store.state.brandSignupPage">
    <div>
      <header
        class="ts-contact-form-container__header ts-text-gray-4 text-center"
      >
        <h1 class="ts-fs-4-1 ts-text-gray-5-a fw-bold text-center mb-01">
          {{ $store.state.brandSignupPage.Form2.title }}
        </h1>
        <p class="ts-fs-7 ts-text-gray-6-iii fw-light mb-05 mb-lg-07">
          {{ $store.state.brandSignupPage.Form2.description }}
        </p>
      </header>

      <form @submit.prevent="submitFrom2">
        <div class="ts-icon-overlay-right mb-2">
          <input
            v-model="password"
            :type="showPassword ? 'text' : 'password'"
            class="form-control form-control-lg rounded-pill"
            id="Password"
            :placeholder="$store.state.brandSignupPage.Form2.password"
            :class="{ 'is-invalid': FromErrorFlags.password }"
          />
          <span
            role="button"
            class="ts-icon-overlay-right__button border-0 p-0 lh-1 btn"
            @click="togglePasswordVisibility"
          >
            <Icon :name="showPassword ? 'eye-off' : 'eye-on'" />
          </span>
          <!-- <div class="invalid-feedback">
            {{ FromErrorMessages.password[0] }}
          </div> -->
        </div>
        <div class="ts-icon-overlay-right mb-08">
          <input
            v-model="confirmPassword"
            :type="confirmShowPassword ? 'text' : 'password'"
            class="form-control form-control-lg rounded-pill"
            id="Confirm Password"
            :placeholder="$store.state.brandSignupPage.Form2.confirmPassword"
            :class="{ 'is-invalid': FromErrorFlags.confirmPassword }"
          />

          <span
            role="button"
            class="ts-icon-overlay-right__button border-0 p-0 lh-1 btn"
            @click="confirmTogglePasswordVisibility"
          >
            <Icon :name="confirmShowPassword ? 'eye-off' : 'eye-on'" />
          </span>
          <!-- <div class="invalid-feedback">
            {{ FromErrorMessages.confirmPassword[0] }}
          </div> -->
        </div>

        <div class="mb-07">
          <p class="ts-fs-7 px-3">
            {{ $store.state.brandSignupPage.Form2.varifiyEmailLabel }}
          </p>
          <div class="ts-custom-email-input">
            <input
              type="email"
              class="form-control form-control-lg rounded-pill"
              id="EmailAddressHelp"
              aria-describedby="EmailAddressHelp"
              :placeholder="
                $store.state.brandSignupPage.Form2.varifiyEmailPlaceholder
              "
              v-model="email"
              :class="{ 'is-invalid': FromErrorFlags.email }"
            />
            <!-- <div class="invalid-feedback">{{ FromErrorMessages.email[0] }}</div> -->
            <button
              @click.prevent="submitFrom2"
              class="ts-btn rounded-pill d-flex align-items-center justify-content-center"
              :class="requestStatus ? 'ts-btn-primary' : 'ts-btn-secondary'"
              :disabled="requestStatus"
            >
              {{ $store.state.brandSignupPage.Form2.varifiyEmailBtn }}
            </button>
          </div>
        </div>
        <div
          class="px-1 px-sm-3 mb-02 mb-lg-07 mt-4 mt-lg-0"
          v-if="$store.state.brandSignupPage"
        >
          <div
            class="form-check d-flex justify-content-center justify-content-sm-start gap-2"
          >
            <input
              class="ts-form-check-input form-check-input rounded-circle mb-1"
              type="checkbox"
              name="sameWhatsNumber"
              id="sameWhatsNumber"
              value="option1"
              v-model="agree_terms"
            />
            <label class="ts-fs-7-ii form-check-label" for="sameWhatsNumber">
              {{ $store.state.brandSignupPage.Form2.AcceptTcLink.textMain }}
              <router-link
                class="ts-text-primary-1"
                target="_blank"
                :to="'/' + $store.state.brandSignupPage.Form2.AcceptTcLink.Link"
              >
                {{ $store.state.brandSignupPage.Form2.AcceptTcLink.textLink }}
              </router-link>
            </label>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
import Icon from "../../../assets/icons/index.vue";
import { axiosAPI } from "../../../axiosClient";
import { toast } from "vue3-toastify";
const requestStatus = ref(false);
export default {
  name: "BrandsForm2",
  props: ["brandId"],
  components: {
    Icon,
  },

  data() {
    return {
      requestStatus: requestStatus,
      password: "",
      showPassword: false,
      confirmPassword: "",
      confirmShowPassword: false,
      email: "",
      agree_terms: false,
      FromErrorFlags: {
        password: false,
        confirmPassword: false,
        email: false,
      },
      FromErrorMessages: {
        password: [],
        confirmPassword: [],
        email: [],
      },
    };
  },
  watch: {
    password() {
      this.FromErrorFlags.password = false;
      this.FromErrorMessages.password = [];
      this.FromErrorFlags.confirmPassword = false;
      this.FromErrorMessages.confirmPassword = [];
    },
    confirmPassword() {
      this.FromErrorFlags.password = false;
      this.FromErrorMessages.password = [];
      this.FromErrorFlags.confirmPassword = false;
      this.FromErrorMessages.confirmPassword = [];
    },
    email() {
      this.FromErrorFlags.email = false;
      this.FromErrorMessages.email = [];
    },
  },
  methods: {
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    confirmTogglePasswordVisibility() {
      this.confirmShowPassword = !this.confirmShowPassword;
    },
    submitFrom2() {
      if (!requestStatus.value) {
        requestStatus.value = true;
        this.$emit("requestStatus", {
          status: true,
        });
        this.FromErrorMessages = {
          password: [],
          confirmPassword: [],
          email: [],
        };
        let errorFlag = false;
        const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        if (this.password == "") {
          this.FromErrorFlags.password = true;
          this.FromErrorMessages.password.push("password_empty");
          errorFlag = true;
        } else {
          this.FromErrorFlags.password = false;
          this.FromErrorMessages.password.pop("password_empty");
        }
        if (this.confirmPassword == "") {
          this.FromErrorFlags.confirmPassword = true;
          this.FromErrorMessages.confirmPassword.push("confirm_password_empty");
          errorFlag = true;
        } else {
          this.FromErrorFlags.confirmPassword = false;
          this.FromErrorMessages.confirmPassword.pop("confirm_password_empty");
        }
        if (this.confirmPassword != "" || this.password != "") {
          if (this.confirmPassword != this.password) {
            this.FromErrorFlags.confirmPassword = true;
            this.FromErrorMessages.confirmPassword.push(
              "confirm_password_missmatch"
            );
            errorFlag = true;
          } else {
            this.FromErrorFlags.confirmPassword = false;
            this.FromErrorMessages.confirmPassword.pop(
              "confirm_password_missmatch"
            );
          }
        }
        if (this.email == "") {
          this.FromErrorFlags.email = true;
          this.FromErrorMessages.email.push("email_empty");
          errorFlag = true;
        } else {
          this.FromErrorFlags.email = false;
          this.FromErrorMessages.email.pop("email_empty");
        }
        if (!regex.test(this.email)) {
          this.FromErrorFlags.email = true;
          this.FromErrorMessages.email.push("invalid_email");
        } else {
          this.FromErrorMessages.email.pop("invalid_email");
        }
        if (errorFlag) {
          setTimeout(() => {
            requestStatus.value = false;
          }, 300);
          this.$emit("submitForm", {
            status: false,
          });
          return false;
        }
        if (this.agree_terms) {
          let FormData = {
            brand_id: this.brandId,
            password: this.password,
            confirm_password: this.confirmPassword,
            email: this.email,
            step: 2,
          };
          let _this = this;
          axiosAPI
            .post(`v1/brands/web/signup`, FormData)
            .then((response) => {
              if (response.data && response.data.status) {
                if (this.$store.state.errorMessges) {
                  toast.success(this.$store.state.errorMessges.otp_sent);
                }
                this.$emit("submitForm", {
                  status: true,
                  brand_id: this.brandId,
                  instagramPin: response.data.instagramPin,
                });
              } else {
                this.$emit("submitForm", {
                  status: false,
                });
              }
              setTimeout(() => {
                requestStatus.value = false;
              }, 300);
            })
            .catch(function (error) {
              _this.$emit("submitForm", {
                status: false,
              });
              if (error.response && error.response.data) {
                let error_msgs = [];
                for (const error_msg in error.response.data) {
                  error_msgs.push(...error.response.data[error_msg]);
                  // _this.FromErrorMessages[error_msg] = error.response.data[error_msg];
                  _this.FromErrorFlags[error_msg] = true;
                }
                if (_this.$store.state.errorMessges) {
                  toast.error(_this.$store.state.errorMessges[error_msgs[0]]);
                }
              }
              setTimeout(() => {
                requestStatus.value = false;
              }, 300);
            });
        } else {
          this.$emit("submitForm", {
            status: false,
          });
          if (this.$store.state.errorMessges) {
            toast.error(this.$store.state.errorMessges.accept_tc);
          }
          setTimeout(() => {
            requestStatus.value = false;
          }, 300);
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.ts-icon-overlay-right {
  position: relative;

  &__button {
    position: absolute;
    right: 18px;
    top: 50%;
    transform: translateY(-50%);
  }
}
</style>
