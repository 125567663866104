<template>
  <div>
    <form @submit.prevent="submitFrom2">
      <!-- Nationality Field -->

      <div
        class="new-signup-form-select-container margin-bottom-16"
        style="height: 100%"
      >
        <div class="new-signup-form-select-icon-container">
          <img src="/images/new creators form/nationality.svg" alt="" />
        </div>
        <select
          class="new-signup-form-dropdown new-signup-form-dropdown-icon new-creator-form-custom-dropdown-class"
          aria-label="Nationality"
          v-model="FormData.nationality_id"
          @change="clearError('nationality_id')"
          :class="{ 'is-invalid': FromErrorFlags.nationality_id }"
        >
          <option value="" disabled hidden>
            {{
              $store.state.creatorSignupPage?.NewCForm1?.FormStep1
                ?.nationalityPlaceholder
            }}
          </option>
          <option
            v-for="nationality in $store.state.nationalities"
            :value="nationality.id"
            :key="'creator-nationality-' + nationality.id"
          >
            {{ nationality.country_name }}
          </option>
        </select>
      </div>

      <!-- Country and City Dropdowns -->
      <div class="d-flex w-100 new-creator-form-gap-16 margin-bottom-16">
        <!-- Country Dropdown -->
        <div class="new-creator-form-flex-1">
          <div class="new-signup-form-select-container" style="height: 100%">
            <div class="new-signup-form-select-icon-container">
              <img
                src="/images/new creators form/country.svg"
                class="new-signup-form-select-icon"
                alt="Country Icon"
              />
            </div>
            <select
              class="new-signup-form-dropdown new-signup-form-dropdown-icon new-creator-form-custom-dropdown-class"
              v-model="FormData.nationality"
              :class="{ 'is-invalid': FromErrorFlags.nationality }"
              @change="clearError('nationality')"
            >
              <option value="" disabled hidden>
                {{ $store.state.creatorSignupPage?.Form1.selectCountry }}
              </option>
              <option
                v-for="country in countries"
                :value="country.id"
                :key="'countries-' + country.id"
              >
                {{ country.country_name }}
              </option>
            </select>
          </div>
        </div>

        <!-- City Dropdown -->
        <div class="new-creator-form-flex-1">
          <div
            class="new-signup-form-select-container new-creator-form-custom-dropdown-class"
            style="height: 100%"
          >
            <div class="new-signup-form-select-icon-container">
              <img
                src="/images/new creators form/city.svg"
                class="new-signup-form-select-icon"
                alt="City Icon"
              />
            </div>
            <select
              class="new-signup-form-dropdown new-signup-form-dropdown-icon new-creator-form-custom-dropdown-class"
              v-model="FormData.state_id"
              :disabled="!FormData.nationality"
              :class="{ 'is-invalid': FromErrorFlags.state_id }"
              @change="clearError('state_id')"
            >
              <option value="" disabled hidden>
                {{ $store.state.creatorSignupPage?.Form1.selectCity }}
              </option>
              <option
                v-for="state in states"
                :value="state.id"
                :key="'locations-' + state.id"
              >
                {{ state.state_name }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <!-- Gender Dropdown -->
      <div class="new-signup-form-select-container" style="height: 100%">
        <div class="new-signup-form-select-icon-container">
          <img
            src="/images/new creators form/gender.svg"
            class="new-signup-form-select-icon"
            alt="Gender Icon"
          />
        </div>
        <select
          class="new-signup-form-dropdown new-signup-form-dropdown-icon new-creator-form-custom-dropdown-class"
          v-model="FormData.gender"
          :class="{ 'is-invalid': FromErrorFlags.gender }"
          @change="clearError('gender')"
        >
          <option hidden disabled value="">
            {{ $store.state.creatorSignupPage.Form1.Gender[0].title }}
          </option>
          <option value="male">
            {{ $store.state.creatorSignupPage.Form1.Gender[1].title }}
          </option>
          <option value="female">
            {{ $store.state.creatorSignupPage.Form1.Gender[2].title }}
          </option>
          <option value="other">
            {{ $store.state.creatorSignupPage.Form1.Gender[3].title }}
          </option>
        </select>
      </div>

      <!-- Birthday Fields -->
      <label
        for="new-form-birthday"
        class="new-creator-form-birthday-label new-creator-form-margin-left-08"
      >
        {{ $store.state.creatorSignupPage?.Form1.birthday }}
      </label>
      <div class="d-flex w-100 new-creator-form-gap-16" id="new-form-birthday">
        <div class="new-creator-form-flex-1">
          <div class="new-signup-form-select-container" style="height: 100%">
            <select
              class="new-signup-form-dropdown new-creator-form-custom-dropdown-class"
              v-model="FormData.year"
              :class="{ 'is-invalid': FromErrorFlags.year }"
              @change="clearError('year')"
            >
              <option value="" disabled hidden>
                {{ $store.state.creatorSignupPage?.dob.yearPlaceholder }}
              </option>
              <option v-for="year in years" :value="year" :key="'year-' + year">
                {{ year }}
              </option>
            </select>
          </div>
        </div>

        <div class="new-creator-form-flex-1">
          <div class="new-signup-form-select-container" style="height: 100%">
            <select
              class="new-signup-form-dropdown new-creator-form-custom-dropdown-class"
              v-model="FormData.month"
              :class="{ 'is-invalid': FromErrorFlags.month }"
              @change="clearError('month')"
            >
              <option value="" disabled hidden>
                {{ $store.state.creatorSignupPage?.dob.monthPlaceholder }}
              </option>
              <option
                v-for="month in months"
                :value="month"
                :key="'month-' + month"
              >
                {{ month }}
              </option>
            </select>
          </div>
        </div>

        <div class="new-creator-form-flex-1">
          <div class="new-signup-form-select-container" style="height: 100%">
            <select
              class="new-signup-form-dropdown new-creator-form-custom-dropdown-class"
              v-model="FormData.day"
              :class="{ 'is-invalid': FromErrorFlags.day }"
              @change="clearError('day')"
            >
              <option value="" disabled hidden>
                {{ $store.state.creatorSignupPage?.dob.dayPlaceholder }}
              </option>
              <option v-for="day in days" :value="day" :key="'day-' + day">
                {{ day }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import { axiosAPI } from "../../../../axiosClient";
import { toast } from "vue3-toastify";

export default {
  name: "CreatorForm2Ver2",
  props: {
    creatorId: {
      type: Number,
      required: true, // Expecting the creatorId from the previous step
    },
  },

  data() {
    const currentYear = new Date().getFullYear();
    const minimumYear = currentYear - 13; // Only allow 13+ years

    return {
      FormData: {
        nationality_id: "",
        nationality: "",
        state_id: "",
        gender: "",
        year: "",
        month: "",
        day: "",
        birthday: "",
      },
      states: [],
      genderOptions: [
        { label: "Male", value: "male" },
        { label: "Female", value: "female" },
      ],
      years: Array.from({ length: 100 }, (_, i) => currentYear - i).filter(
        (year) => year <= minimumYear
      ), // Filter to show only eligible years
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      days: Array.from({ length: 31 }, (_, i) => i + 1),

      FromErrorFlags: {
        nationality_id: false,
        nationality: false,
        state_id: false,
        gender: false,
        year: false,
        month: false,
        day: false,
        birthday: false,
      },
      FromErrorMessages: {
        nationality_id: [],
        nationality: [],
        state_id: [],
        gender: [],
        year: [],
        month: [],
        day: [],
        birthday: [],
      },
    };
  },

  computed: {
    countries() {
      return this.$store.state.countries;
    },
  },
  watch: {
    "FormData.nationality": function (newCountryId) {
      if (newCountryId) {
        this.getLocations(newCountryId);
      }
    },
  },
  methods: {
    clearError(field) {
      if (this.FromErrorFlags[field]) {
        this.FromErrorFlags[field] = false;
        this.FromErrorMessages[field] = [];
      }
    },
    async getLocations(nationality) {
      try {
        const response = await axiosAPI.get(
          `v1/locations/fetch/${nationality}`
        );
        if (response.data && response.data.success) {
          this.states = response.data.data;
          this.FormData.state_id = "";
        }
      } catch (error) {
        console.error("Failed to fetch cities", error);
        toast.error("Failed to fetch cities. Please try again.", {
          toastId: "Failed to fetch cities. Please try again.",
        });
      }
    },

    async submitFrom2() {
      return new Promise((resolve) => {
        // Reset error flags
        this.resetErrorFlags();

        // Validate the form fields
        const isValid = this.validateForm();
        if (!isValid) {
          this.$emit("submitForm", { status: false });
          resolve({ status: false });
          return;
        }

        // Format the birthdate as "YYYY-MM-DD"
        const monthIndex = this.months.indexOf(this.FormData.month); // Convert month name to index (0-based)
        if (monthIndex === -1) {
          toast.error("Invalid month selected.");
          this.$emit("submitForm", { status: false });
          resolve({ status: false });
          return;
        }

        this.FormData.birthday = `${this.FormData.year}-${String(
          monthIndex + 1
        ).padStart(2, "0")}-${String(this.FormData.day).padStart(2, "0")}`;
        this.FormData.step = 2;
        this.FormData.creator_id = this.creatorId;

        // Proceed with form submission
        axiosAPI
          .post(`/v1/creator/signup`, this.FormData)
          .then((response) => {
            if (response.data && response.data.status) {
              const { instagramPin } = response.data.data;

              this.$emit("submitForm", {
                status: response.data.status,
                instagramPin: instagramPin,
              });

              resolve({
                status: response.data.status,
                instagramPin: instagramPin,
              });
            } else {
              toast.error(
                response.data.message || "Submission failed. Please try again.",
                {
                  toastId:
                    response.data.message ||
                    "Submission failed. Please try again.",
                }
              );
              this.$emit("submitForm", { status: response.data.status });
              resolve({ status: response.data.status });
            }
          })
          .catch((error) => {
            this.$emit("submitForm", { status: false });
            if (error.response && error.response.data) {
              let error_msgs = [];
              for (const error_msg in error.response.data) {
                error_msgs.push(...error.response.data[error_msg]);
                this.FromErrorFlags[error_msg] = true;
              }
              if (error_msgs.length > 0) {
                toast.error(error_msgs[0], { toastId: error_msgs[0] });
              }
            } else {
              toast.error("An unexpected error occurred. Please try again.", {
                toastId: "An unexpected error occurred. Please try again2.",
              });
            }
            resolve({ status: false });
          });
      });
    },

    validateForm() {
      let isValid = true;
      const errorMessages = [];

      // Validate nationality
      if (!this.FormData.nationality_id) {
        this.FromErrorFlags.nationality_id = true;
        this.FromErrorMessages.nationality_id.push("Nationality is required.");
        errorMessages.push("Nationality is required.");
        isValid = false;
      }

      // Validate country
      if (!this.FormData.nationality) {
        this.FromErrorFlags.nationality = true;
        this.FromErrorMessages.nationality.push("Country is required.");
        errorMessages.push("Country is required.");
        isValid = false;
      }

      // Validate state (city)
      if (!this.FormData.state_id) {
        this.FromErrorFlags.state_id = true;
        this.FromErrorMessages.state_id.push("City is required.");
        errorMessages.push("City is required.");
        isValid = false;
      }

      // Validate gender
      if (!this.FormData.gender) {
        this.FromErrorFlags.gender = true;
        this.FromErrorMessages.gender.push("Gender is required.");
        errorMessages.push("Gender is required.");
        isValid = false;
      }

      // Validate year
      if (!this.FormData.year) {
        this.FromErrorFlags.year = true;
        this.FromErrorMessages.year.push("Year is required.");
        errorMessages.push("Year is required.");
        isValid = false;
      }

      // Validate month
      if (!this.FormData.month) {
        this.FromErrorFlags.month = true;
        this.FromErrorMessages.month.push("Month is required.");
        errorMessages.push("Month is required.");
        isValid = false;
      }

      // Validate day
      if (!this.FormData.day) {
        this.FromErrorFlags.day = true;
        this.FromErrorMessages.day.push("Day is required.");
        errorMessages.push("Day is required.");
        isValid = false;
      }
      // Validate date of birth (age should be at least 13)
      if (this.FormData.year && this.FormData.month && this.FormData.day) {
        const selectedDate = new Date(
          this.FormData.year,
          this.months.indexOf(this.FormData.month),
          this.FormData.day
        );
        const currentDate = new Date();
        const age = currentDate.getFullYear() - selectedDate.getFullYear();
        const isBeforeBirthdayThisYear =
          currentDate.getMonth() < selectedDate.getMonth() ||
          (currentDate.getMonth() === selectedDate.getMonth() &&
            currentDate.getDate() < selectedDate.getDate());

        if (age < 13 || (age === 13 && isBeforeBirthdayThisYear)) {
          this.FromErrorFlags.birthday = true;
          this.FromErrorMessages.birthday.push(
            "You must be at least 13 years old."
          );
          console.log("You must be at least 13 years old.");
          errorMessages.push("You must be at least 13 years old.");
          isValid = false;
        }
      }
      // Show all errors in a single toast if there are any
      if (errorMessages.length > 0) {
        errorMessages.forEach((error) => {
          toast.error(error, { toastId: error }); // Display each error in its own toast
        });
        // toast.error(errorMessages.join("\n"));
      }

      return isValid;
    },

    resetErrorFlags() {
      // Reset error flags and messages for all fields
      for (const key in this.FromErrorFlags) {
        this.FromErrorFlags[key] = false;
      }

      for (const key in this.FromErrorMessages) {
        this.FromErrorMessages[key] = [];
      }
    },
  },
};
</script>

<style>
.new-creator-form-flex-1 {
  flex: 1;
  max-width: calc(50% - 1rem / 2);
}

.new-creator-form-gap-16 {
  gap: 1rem;
}
.new-creator-form-margin-left-08 {
  margin-left: 0.5rem;
}

.new-creator-form-custom-dropdown-class {
  height: 54px;
}

.new-creator-form-birthday-label {
  text-align: left;
  display: block;
  margin-top: 27px;
  margin-bottom: 4px;
  font-size: clamp(1rem, 0.5rem + 1.5vw, 1.125rem);
  color: #4d4d4d;
}

.is-invalid {
  outline: 1px solid red;
}
@media (max-width: 508px) {
  .new-creator-form-custom-dropdown-title {
    max-width: 100px !important;
  }
}
@media (max-width: 450px) {
  .new-creator-form-custom-dropdown-title {
    max-width: 80px !important;
  }
}
@media (max-width: 400px) {
  .new-creator-form-custom-dropdown-title {
    max-width: 70px !important;
  }
}
@media (max-width: 380px) {
  .new-creator-form-custom-dropdown-title {
    max-width: 60px !important;
  }
}
</style>
