import { defineComponent } from "vue";

export const ComponentIcon = defineComponent({
  props: {
    theme: { type: String },
    type: { type: String },
    path: {
      type: String,
      required: false,
      default: "",
    },
  },
  setup(props) {
    // Function to determine which icon to display
    const renderIcon = () => {
      if (props.type === "success") {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            id="Layer_2"
            data-name="Layer 2"
            viewBox="0 0 67 67"
          >
            <g id="Layer_1-2" data-name="Layer 1">
              <circle
                id="Ellipse_194"
                cx="33.5"
                cy="33.5"
                r="33.5"
                fill="#00a4b6"
                data-name="Ellipse 194"
              ></circle>
              <path
                id="Path_1219"
                fill="#fff"
                d="M29.27 49.54c-1 0-1.95-.43-2.62-1.18L16.07 36.44a3.506 3.506 0 0 1 .29-4.94 3.506 3.506 0 0 1 4.94.29l7.76 8.74 16.46-21.68a3.494 3.494 0 0 1 4.9-.67 3.494 3.494 0 0 1 .67 4.9L32.05 48.15a3.51 3.51 0 0 1-2.65 1.38h-.13Z"
                data-name="Path 1219"
              ></path>
            </g>
          </svg>
        );
      } else if (props.type === "error") {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            id="Layer_2"
            data-name="Layer 2"
            viewBox="0 0 67 67"
          >
            <g id="Layer_1-2" data-name="Layer 1">
              <circle
                id="Ellipse_195"
                cx="33.5"
                cy="33.5"
                r="33.5"
                fill="#d76262"
                data-name="Ellipse 195"
              ></circle>
              <path
                id="Union_97"
                fill="#fff"
                d="M18.3 52.2a3.501 3.501 0 0 1-3.5-3.49c0-.89.34-1.79 1.02-2.48L28.55 33.5 15.82 20.77c-.68-.68-1.03-1.58-1.03-2.48s.34-1.79 1.03-2.47a3.495 3.495 0 0 1 4.95 0L33.5 28.55l12.73-12.73a3.495 3.495 0 0 1 5.97 2.48c0 .9-.34 1.79-1.03 2.47L38.44 33.49l12.72 12.72c.68.68 1.03 1.58 1.03 2.47s-.34 1.79-1.03 2.47a3.495 3.495 0 0 1-4.95 0L33.49 38.43 20.76 51.16c-.68.68-1.58 1.03-2.48 1.03Z"
                data-name="Union 97"
              ></path>
            </g>
          </svg>
        );
      }
      return null; // Default case if type is not 'success' or 'error'
    };

    return () => renderIcon();
  },
});

export default {
  ComponentIcon,
};
