<template>
  <LoadingScreen v-if="!$store.state.teamPage" />
  <div>
    <div class="ts-team-hero mb-07 mb-lg-10">
      <img
        v-if="$store.state.teamPage"
        class="ts-team-hero-img w-100"
        :src="
          $store.state.baseUrl +
          $store.state.teamPage.HeroImage.data.attributes.url
        "
        :alt="$store.state.teamPage.HeroImage.data.attributes.alternativeText"
      />
    </div>
    <section>
      <div class="ts-container-fluid container-fluid">
        <header
          class="ts-team__header text-center mb-07 mb-lg-12"
          v-if="$store.state.teamPage"
        >
          <h1
            class="ts-fs-1 ts-text-gray-5 fw-light text-center mb-04 mb-lg-07"
          >
            {{ $store.state.teamPage.Title.titleMain }}
            <span class="fw-bold">
              {{ $store.state.teamPage.Title.titleHighlight }}</span
            >
          </h1>
          <VueMarkdown
            class="ts-fs-5 fw-light mb-05 mb-lg-07 text-center"
            :source="$store.state.teamPage.TeamDesc"
          />
        </header>
        <div class="ts-team-cards-group" v-if="$store.state.departments">
          <img
            class="ts-bg"
            src="/images/Contact/contact-form-bg.svg"
            alt="alternativeText"
          />

          <div class="mb-07 mb-lg-08 d-md-none">
            <select
              class="form-select ts-form-select rounded-pill py-2"
              aria-label="Default select example"
              @change="updateActiveDepartment2"
            >
              <option
                v-for="tag in $store.state.departments"
                :key="tag.attributes.title"
                :data-title="tag.attributes.title"
                :value="tag.attributes.title"
              >
                {{ tag.attributes.title }}
              </option>
            </select>
          </div>
          <div
            class="d-none d-md-flex justify-content-center flex-wrap gap-2 mb-14 ts-rtl-row-reverse"
          >
            <button
              class="ts-btn-tags"
              v-for="tag in $store.state.departments"
              :key="tag.attributes.title"
              :data-title="tag.attributes.title"
              :class="{
                'ts-btn-tags--active':
                  $store.state.activeDepartment === tag.attributes.title,
              }"
              @click="updateActiveDepartment"
            >
              {{ tag.attributes.title }}
            </button>
          </div>
          <!-- Loop departments -->
          <div
            v-for="department in $store.state.departments"
            :key="department.id"
          >
            <!-- Show only Active department -->
            <div
              v-if="
                department.attributes.title == $store.state.activeDepartment
              "
            >
              <div v-if="department.attributes.team_groups.data.length < 1">
                <h1 v-if="$store.state.teamPage" class="text-center">
                  {{ $store.state.teamPage.noDataFound }}
                </h1>
              </div>
              <!-- Loop Team Cards -->
              <div v-else class="ts-team-card-group mb-5">
                <div
                  v-for="(teamCard, index) in sortedTeamMembers(
                    department.attributes.team_groups.data
                  )"
                  :key="teamCard.id"
                  class="ts-team-card-group__item"
                >
                  <TeamCard
                    v-if="index + 1 <= itemsToShow"
                    :name="teamCard.attributes.name"
                    :designation="teamCard.attributes.designation"
                    :linkSrc="teamCard.attributes.link"
                    :imageUrl="teamCard.attributes.image.data.attributes.url"
                  />
                </div>
              </div>
            </div>
          </div>

          <!-- v-if="itemsToShow <= $store.state.departments.length" -->
          <div class="text-center" v-if="$store.state.teamPage">
            <button
              v-if="!hideShowMoreButton"
              @click="showMore"
              class="ts-btn ts-btn--sm-lg ts-btn-outline-primary rounded-pill px5"
            >
              {{ $store.state.teamPage.showMore }}
            </button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import LoadingScreen from "../components/Common/LoadingScreen.vue";
import TeamCard from "../components/Team/Card.vue";
import VueMarkdown from "markdown-vue";
export default {
  name: "TeamPage",
  components: {
    LoadingScreen,
    TeamCard,
    VueMarkdown,
  },

  beforeMount() {
    // Add classes to Navbar
    const navBarCustomClasses = "ts-bg-white";
    this.$emit("data-navBarCustomClasses", navBarCustomClasses);
    // Fetching Data
    this.callDepartments();
    this.callPageData();
    this.updateLocalData();
    this.hideShowMoreButtonFunc(); // Hide show more button if
  },

  beforeUnmount() {
    // Remove classes to Navbar
    const navBarCustomClasses = "";
    this.$emit("data-navBarCustomClasses", navBarCustomClasses);
  },

  data() {
    return {
      itemsToShow: 12, // Initial number of items to show
      hideShowMoreButton: true,
    };
  },

  computed: {
    visibleCards() {
      return this.cards.slice(0, this.itemsToShow);
    },
    activeLanguage() {
      return this.$store.getters.activeLanguage;
    },
    afterDataFetched() {
      return this.$store.state.departments;
    },
  },

  // Watch if ACtive language Change but not same as old Language
  watch: {
    activeLanguage(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.callDepartments();
        this.callPageData();
        this.updateLocalData();
        this.hideShowMoreButtonFunc(); // Hide show more button if
      }
    },
    afterDataFetched(newValue, oldValue) {
      if (newValue !== oldValue) {
        //  this.orderDepartments();
        this.hideShowMoreButtonFunc();
      }
    },
  },
  // created() {
  //   this.callDepartments();
  //   this.callPageData();
  //   this.updateLocalData();
  //   this.hideShowMoreButtonFunc(); // Hide show more button if
  // },

  methods: {
    updateActiveDepartment(event) {
      const clickedElement = event.target;
      // // Get the data attribute value
      const title = clickedElement.getAttribute("data-title");
      // // change the active button State
      this.$store.dispatch("updateActiveDepartment", title);
      this.hideShowMoreButtonFunc();
    },

    updateActiveDepartment2(event) {
      this.$store.dispatch("updateActiveDepartment", event.target.value);
      this.hideShowMoreButtonFunc();
    },
    sortedTeamMembers(teamMembers) {
      return [...teamMembers].sort((a, b) => {
        const orderA = a.attributes.teamMemberOrder || Number.MAX_SAFE_INTEGER; // Default to a very large number if no order
        const orderB = b.attributes.teamMemberOrder || Number.MAX_SAFE_INTEGER; // Default to a very large number if no order

        return orderA - orderB;
      });
    },
    showMore() {
      this.itemsToShow += 4; // Increase the number of items to show
      this.hideShowMoreButtonFunc();
    },

    hideShowMoreButtonFunc() {
      if (this.$store.state.departments) {
        for (let department in this.$store.state.departments) {
          let tempDepartment =
            this.$store.state.departments[department].attributes;

          if (tempDepartment.title === this.$store.state.activeDepartment) {
            // If the active department cards are less  then the active card we've shwing  then we hide show more button
            if (this.itemsToShow >= tempDepartment.team_groups.data.length) {
              this.hideShowMoreButton = true;
            } else {
              this.hideShowMoreButton = false;
            }
          }
        }
      }
    },
    callDepartments() {
      this.$store.dispatch("getDepartments");
    },
    callPageData() {
      this.$store.dispatch("getTeamPage");
    },
    updateLocalData() {
      // $store.state.activeDepartment.attributes.title
    },
  },
};
</script>
<style lang="scss" scoped>
body {
  padding-top: 77px !important;
}

.ts-team-card-group {
  display: grid;
  row-gap: clamp(2.3125rem, 4vw, 3rem);
  column-gap: clamp(2.3125rem, 4vw, 3.1875rem);
  grid-template-columns: repeat(1, 1fr);

  @media (min-width: 500px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 992px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (min-width: 1201px) {
    grid-template-columns: repeat(4, 1fr);
  }
  &__item {
    &:empty {
      display: none;
    }
  }
}
.ts-team-hero {
  min-height: 593px;
  max-height: 1080px;

  @media (max-width: 991px) {
    min-height: 360px;
    object-fit: cover;
  }
  &-img {
    position: relative;
    z-index: 100;
    min-height: inherit;
    max-height: inherit;
    object-fit: cover;
    @media (max-width: 991px) {
      min-height: inherit;
      object-fit: cover;
    }
  }
}

.ts-team__header {
  max-width: 980px;
  margin-inline: auto;
}

.ts-team-cards-group {
  position: relative;
  max-width: 1228px;
  margin: auto;
  margin-bottom: 150px;
  isolation: isolate;
  .ts-bg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: clamp(800px, 80vw, 900px);
    z-index: -1;
  }
}

.ts-btn-tags {
  font-size: 23px;
  border-radius: 50rem;
  border: none;
  padding: 10px 1rem 13px;
  line-height: 100%;
  transition: all 300ms ease-in-out;
  background-color: transparent;
  border: 1px solid #00a4b600;

  &--active {
    // background-color: #00A4B6;
    border: 1px solid #00a4b6;
    color: #00a4b6;
    padding-inline: 2.5rem;
  }
}
</style>
