<template>
  <section class="ts-brands-logos">
    <h1
      class="ts-fs-1 ts-text-gray-5 fw-bold text-center mb-06 mb-lg-10"
      v-if="$store.state.brandsSwiper"
    >
      {{ $store.state.brandsSwiper.Title }}
    </h1>
    <div
      class="ts-brands-logos__content position-relative overflow-hidden"
      v-if="$store.state.brandsSwiper"
    >
      <Vue3Marquee
        class="mb-4"
        :duration="$store.state.brandsSwiper.speed"
        direction="reverse"
        :key="marqueeKey"
      >
        <div
          v-for="brandItem in $store.state.brandsSwiper.Images.data"
          :key="brandItem.id"
        >
          <img
            class="ts-logo-img"
            width="90"
            :src="$store.state.baseUrl + brandItem.attributes.url"
            :alt="brandItem.attributes.alternativeText"
          />
        </div>
      </Vue3Marquee>
      <Vue3Marquee
        :duration="$store.state.brandsSwiper.speed"
        :key="marqueeKey"
      >
        <div
          v-for="brandItem in $store.state.brandsSwiper.Images2.data"
          :key="brandItem.id"
        >
          <img
            class="ts-logo-img"
            width="90"
            :src="$store.state.baseUrl + brandItem.attributes.url"
            :alt="brandItem.attributes.alternativeText"
          />
        </div>
      </Vue3Marquee>
    </div>
  </section>
</template>

<script>
import { Vue3Marquee } from "vue3-marquee";

export default {
  name: "BrandsLogos",
  components: {
    Vue3Marquee,
  },
  props: {
    msg: String,
  },

  data() {
    return {
      marqueeKey: 1,
    };
  },

  computed: {
    activeLanguage() {
      return this.$store.getters.activeLanguage;
    },
  },

  // Watch if Active language Change but not the same as the old Language
  watch: {
    activeLanguage(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$store.dispatch("getBrands");
        this.marqueeKey += 1;
      }
    },
  },
  created() {
    this.$store.dispatch("getBrands");
  },
};
</script>

<style scoped lang="scss">
.loop-container {
  overflow: hidden;
  display: flex;
  align-items: center;
  width: calc(2400px + 22rem);
  margin: 0 auto;
}

.ts-logo-img {
  /*margin-inline: clamp(0.25rem, 1.5vw, 0.8rem);*/
  margin-inline: 13px;
}

.ts-brands-logos {
  &__content {
    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      width: 100px;
      height: 100%;
      z-index: 10;
      pointer-events: none;
    }
    @media (max-width: 768px) {
      &::before,
      &::after {
        content: "";

        width: 50px;
      }
      &::before {
        transform: translateX(-1px);
      }

      &::after {
        transform: translateX(1px);
      }
    }

    &::before {
      left: 0;
      background: rgb(255, 255, 255);
      background: -moz-linear-gradient(
        90deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 100%
      );
      background: -webkit-linear-gradient(
        90deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 100%
      );
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#ffffff", GradientType=1);
    }

    &::after {
      right: 0;

      background: rgb(255, 255, 255);
      background: -moz-linear-gradient(
        270deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 100%
      );
      background: -webkit-linear-gradient(
        270deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 100%
      );
      background: linear-gradient(
        270deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#ffffff", GradientType=1);
    }
  }
}
</style>
