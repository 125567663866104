<template>
  <div class="steps-container">
    <div
      v-for="(step, index) in totalSteps"
      :key="index"
      :class="['step-dot', { active: index === activeStep }]"
      @click="handleStepClick(index)"
    ></div>
  </div>
</template>

<script>
export default {
  name: "StepsDots",
  props: {
    totalSteps: {
      type: Number,
      required: true,
    },
    activeStep: {
      type: Number,
      required: true,
    },
  },
  methods: {
    handleStepClick(index) {
      if (index < this.activeStep) {
        this.$emit("navigate", index + 1); // Emit event with the 1-based step index
      }
    },
  },
};
</script>

<style scoped>
.steps-container {
  display: flex;
  gap: 12px; /* spacing between dots */
  justify-content: center;
  align-items: center;
  margin-block: 24px;
}

.step-dot {
  width: 12px;
  height: 12px;
  border-radius: 9px;
  background-color: #b7b7b7;
  transition: width 0.3s ease, background-color 0.3s ease;
  cursor: pointer;
}

.step-dot.active {
  width: 21px;
  height: 12px;
  background-color: #00a4b6;
}
</style>
