<template>
  <div class="newform-congratulations">
    <img src="/images/new creators form/congratulations.svg" alt="" />
    <h2>{{ heading }}</h2>
    <p>{{ message }}</p>
    <p>{{ notification }}</p>
  </div>
</template>

<script>
export default {
  name: "NewFormCongratulations",
  props: {
    heading: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
    notification: {
      type: String,
      required: true,
    },
  },
};
</script>
<style scoped>
.newform-congratulations {
  margin-bottom: 125px;
}
img {
  margin-bottom: 2rem;
  margin-right: 50px;
}
h2 {
  color: #403e3e;
  font-size: 28px;
  font-weight: 600;
  line-height: 1.25;
  margin-bottom: 18px;
}
p {
  font-size: 18px;
  line-height: 1.5;
  margin: 0;
}
@media (max-width: 500px) {
  p {
    font-size: 18px;
    padding-inline: 0;
  }
}
</style>
