<template>
  <LoadingScreen v-if="!$store.state.creatorSignupPage" />
  <div>
    <CreatorSignupHeroVer3 />
  </div>
</template>
<script>
import LoadingScreen from "../components/Common/LoadingScreen.vue";
import CreatorSignupHeroVer3 from "../components/Form/CreatorSignupHeroVer3.vue";

export default {
  name: "CreatorSignup",
  components: {
    LoadingScreen,
    CreatorSignupHeroVer3,
  },
  computed: {
    activeLanguage() {
      return this.$store.getters.activeLanguage;
    },
  },
  // Watch if ACtive language Change but not same as old Language
  watch: {
    activeLanguage(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$store.dispatch("getCreatorSignupPage");
      }
    },
  },
  created() {
    this.$store.dispatch("getCreatorSignupPage");
  },
};
</script>
