import store from "./state";
import { axiosClient, axiosAPI } from "../axiosClient";

// Components

// lANGUAGE
export const getLnaguages = ({ commit }) => {
  axiosClient.get(`api/i18n/locales`).then((response) => {
    commit("SET_LANGUAGES", response.data);
  });
};

export const switchLanguge = ({ commit }, langugeCode) => {
  commit("SWITCH_LANGUAGE", langugeCode);
};
export const setIsRtl = ({ commit }, istrue) => {
  commit("SET_RTL", istrue);
};
export const updateActiveDepartment = ({ commit }, department) => {
  commit("SET_ACTIVE_DEPARTMENT", department);
};

// HEADER
export const getHeader = ({ commit }) => {
  axiosClient
    .get(
      `api/header?locale=${store.activeLanguage}&populate[0]=logoSmall&populate[1]=logoLarge&populate[2]=DropDown.DropdownItem &populate[3]=PageLink`
    )
    .then((response) => {
      commit("GET_HEADER", response.data.data.attributes);
    });
};
// Brands
export const getBrands = ({ commit }) => {
  axiosClient
    .get(`api/brand-swiper?locale=${store.activeLanguage}&populate=*`)
    .then((response) => {
      commit("GET_BRANDS", response.data.data.attributes);
    });
};
// Brand Love Us
export const getBrandLoveUs = ({ commit }) => {
  axiosClient
    .get(
      `api/brand-love-uses?locale=${store.activeLanguage}&populate[0]=Contact.Image`
    )
    .then((response) => {
      commit("GET_BRAND_LOVE_US", response.data.data);
    });
};
// Creator Love Us
export const getCreatorLoveUs = ({ commit }) => {
  axiosClient
    .get(
      `api/creator-love-uses?locale=${store.activeLanguage}&populate[0]=Contact.Image&populate[1]=SocialMedia.SocialMediaLink.Image`
    )
    .then((response) => {
      commit("GET_CREATOR_LOVE_US", response.data.data);
    });
};

// CAREERS
export const getCareers = ({ commit }) => {
  axiosClient
    .get(
      `api/careers?locale=${store.activeLanguage}&populate=*&pagination[page]=1&pagination[pageSize]=9999`
    )
    .then((response) => {
      commit("GET_CAREER", response.data.data);
    });
};
// Footer
export const getFooter = ({ commit }) => {
  axiosClient
    .get(
      `api/footer?locale=${store.activeLanguage}&populate[0]=logo.src&populate[1]=Brands.link&populate[2]=Creators.link&populate[3]=About.link&populate[4]=SocialMediaGroup.SocialMedia.Image`
    )
    .then((response) => {
      commit("GET_FOOTER", response.data.data.attributes);
    });
};

//  PAGES
export const getBrandFaqPage = ({ commit }) => {
  axiosClient
    .get(
      `api/faq?locale=${store.activeLanguage}&populate=*&pagination[page]=1&pagination[pageSize]=9999`
    )
    .then((response) => {
      commit("GET_BRANDS_FAQ_PAGE", response.data.data.attributes);
    });
};

// CAREER PAGE
export const getCareerPage = ({ commit }) => {
  axiosClient
    .get(
      `api/page-career?locale=${store.activeLanguage}&populate[1]=Banner.smallImage&populate[2]=Banner.largeImage&populate[3]=Careers&populate[4]=jobApplication.formPlaceHolder&populate[5]=Success&populate[6]=errorMessages`
    )
    .then((response) => {
      commit("GET_CAREER_PAGE", response.data.data.attributes);
    });
};
// contact
export const getContactUsPage = ({ commit }) => {
  axiosClient
    .get(
      `api/page-contact?locale=${store.activeLanguage}&populate[1]=FormPlaceholder.Companies&populate[2]=Success&populate[3]=errorMessages`
    )
    .then((response) => {
      commit("GET_CONTACT_US", response.data.data.attributes);
    });
};
// CREATOR
export const getCreatorPage = ({ commit }) => {
  axiosClient
    .get(
      `api/page-creator?locale=${store.activeLanguage}&populate[0]=Banner.Signup&populate[1]=HowItWorks.image&populate[2]=HowItWorks.Cards&populate[3]=Testimonial&populate[4]=Banner.bannerImages.smallImage&populate[5]=Banner.bannerImages.mediumImage&populate[6]=Banner.bannerImages.largeImage&populate[7]=Banner.Login`
    )
    .then((response) => {
      commit("GET_CREATOR_PAGE", response.data.data.attributes);
    });
};
// BRAND
export const getBrandPage = ({ commit }) => {
  axiosClient
    .get(
      `api/page-brand?locale=${store.activeLanguage}&populate[0]=Banner.GetADemo&populate[1]=InfluencerMarketing.Title3&populate[2]=HowItWork.subtitle&populate[3]=HowItWork.Cards&populate[4]=Testimonial&populate[5]=Banner.bannerImages.smallImage&populate[6]=Banner.bannerImages.mediumImage&populate[7]=Banner.bannerImages.largeImage&populate[8]=Banner.Signup`
    )
    .then((response) => {
      commit("GET_BRAND_PAGE", response.data.data.attributes);
    });
};

// new sign up page
export const getNewSignUpForm = ({ commit }) => {
  axiosClient
    .get(
      `api/page-new-brand-signup?locale=${store.activeLanguage}&populate[0]=fullNamePlaceholder&populate[1]=fullNameLabel&populate[2]=emailPlaceholder&populate[3]=emailLabel&populate[4]=phonePlaceholder&populate[5]=phoneLabel&populate[6]=websitePlaceholder&populate[7]=websiteLabel&populate[8]=submitButton&populate[9]=signInText&populate[10]=signInLink&populate[11]=getDemoTitle&populate[12]=getDemoDescription&populate[13]=images&populate[14]=imagesMobile`
    )
    .then((response) => {
      commit("GET_NEW_SIGNUP_FORM", response.data.data.attributes);
    });
};

// HOME
export const getHomePage = ({ commit }) => {
  axiosClient
    .get(
      `api/page-home?locale=${store.activeLanguage}&populate[0]=Banner.Creator&populate[1]=Banner.Brands&populate[2]=Banner.Campaigns&populate[3]=Banner.brandButton&populate[4]=Banner.creatorButton&populate[5]=AsABrand.Title&populate[6]=AsABrand.Image&populate[7]=AsABrand.GetDemoButton&populate[8]=AsABrand.LearnMoreButton&populate[9]=AsACreator.Title&populate[10]=AsACreator.Image&populate[11]=AsACreator.SignUpButton&populate[12]=AsACreator.LearnMoreButton&populate[13]=Banner.backgroundImage`
    )
    .then((response) => {
      commit("GET_Home_PAGE", response.data.data.attributes);
    });
};
export const getPageNotFound = ({ commit }) => {
  axiosClient
    .get(
      `api/page-404?locale=${store.activeLanguage}&populate[0]=link&populate[1]=ImageLarge&populate[2]=ImageMedium&populate[3]=ImageSmall`
    )
    .then((response) => {
      commit("GET_PAGE_NOT_FOUND", response.data.data.attributes);
    });
};
// SUBSCRIPTIONS
export const subscriptionsPage = ({ commit }) => {
  axiosClient
    .get(
      `api/subscription?locale=${store.activeLanguage}&populate[0]=AddOns.addonCard.image&populate[1]=AddOns.priorityPasses.image&populate[2]=PlansOverview.subscriptionPlanList`
    )
    .then((response) => {
      commit("GET_SUBSCRIPTION_PAGE", response.data.data.attributes);
    });
};
export const subscriptionsPlans = ({ commit }) => {
  axiosClient
    .get(
      `api/subscriptions-plans?locale=${store.activeLanguage}&populate[0]=GetStartedButton&populate[1]=featureList&populate[3]=subscriptions_duration`
    )
    .then((response) => {
      let unSortedPlans = response.data.data;
      const sortedPlans = unSortedPlans.sort((a, b) => {
        const orderA =
          parseInt(a.attributes.cardOrder) || Number.MAX_SAFE_INTEGER;
        const orderB =
          parseInt(b.attributes.cardOrder) || Number.MAX_SAFE_INTEGER;

        return orderA - orderB;
      });
      commit("GET_SUBSCRIPTION_PLANS", sortedPlans);
    });
};
export const subscriptionsDurations = ({ commit }) => {
  axiosClient
    .get(`api/subscriptions-durations?locale=${store.activeLanguage}`)
    .then((response) => {
      commit("GET_SUBSCRIPTION_DURATIONS", response.data.data);
    });
};

// HOME
export const getOverviewPage = ({ commit }) => {
  axiosClient
    .get(
      `api/page-overview?locale=${store.activeLanguage}&populate[0]=DetailedAnalytics.backgroundImage&populate[1]=SaveWithAlist.Image&populate[2]=VettedCreators.backgroundImage&populate[3]=ContentUsage.backgroundImage&populate[4]=LiveSupport.Image&populate[5]=PrivateFeedback.backgroundImage&populate[6]=PublicReviews.Image&populate[7]=VettedBusinesses.Image&populate[8]=EfficientMatching.Image&populate[9]=EfficientMatching.backgroundImage&populate[10]=ContentModeration.Image&populate[11]=ContentGuidelines.Image&populate[12]=VoucherTracking.Image&populate[13]=VoucherTracking.backgroundImage`
    )
    .then((response) => {
      commit("GET_OVERVIEW_PAGE", response.data.data.attributes);
    });
};

export const getCreatorTc = ({ commit }) => {
  axiosClient
    .get(
      `api/page-creator-tc?locale=${store.activeLanguage}&populate[0]=TermsAndConditions`
    )
    .then((response) => {
      commit("GET_CREATOR_TC", response.data.data.attributes);
    });
};
export const getBrandTc = ({ commit }) => {
  axiosClient
    .get(
      `api/page-brand-tc?locale=${store.activeLanguage}&populate[0]=TermsAndConditions`
    )
    .then((response) => {
      commit("GET_CREATOR_TC", response.data.data.attributes);
    });
};
export const getPrivacyTc = ({ commit }) => {
  axiosClient
    .get(
      `api/page-privacy-tc?locale=${store.activeLanguage}&populate[0]=TermsAndConditions`
    )
    .then((response) => {
      commit("GET_CREATOR_TC", response.data.data.attributes);
    });
};
export const getErrorMessages = ({ commit }) => {
  axiosClient
    .get(
      `api/component-error-message?locale=${store.activeLanguage}&populate=*`
    )
    .then((response) => {
      commit("GET_ERROR_MESSAGES", response.data.data.attributes);
    });
};
// creator signup page
export const getBrandSignupPage = ({ commit }) => {
  axiosClient
    .get(
      `api/page-brand-signup?locale=${store.activeLanguage}&populate[0]=errorMessages&populate[1]=mainDetails.TestimonialContact&populate[2]=Form1&populate[3]=Form2.AcceptTcLink&populate[4]=Form3&populate[5]=Congratulation&populate[6]=PageLinks.alreadyHaveAnAccount&populate[7]=PageLinks.areYouACreator&populate[8]=FormActions&populate[9]=GetADemo&populate[10]=mainDetails.TestimonialContact.Image`
    )
    .then((response) => {
      commit("GET_BRAND_SIGNUP_PAGE", response.data.data.attributes);
    });
};
// creator signup page
export const getCreatorSignupPage = ({ commit }) => {
  const confirmationModals =
    "&populate[12]=ConfirmModelInsta.socialMediaBtn&populate[13]=ConfirmModelTiktok.socialMediaBtn";
  axiosClient
    .get(
      `api/creator-signup?locale=${store.activeLanguage}&populate[0]=errorMessages&populate[1]=mainDetails&populate[2]=Form1.Gender&populate[3]=Form2&populate[4]=Form3&populate[5]=Form4.AcceptTCLink&populate[6]=mainDetails.TestimonialContact.Image&populate[7]=PageLinks.alreadyHaveAnAccount&populate[8]=PageLinks.areYouABrand&populate[9]=socialVarification&populate[10]=Congratulation&populate[11]=FormActions${confirmationModals}&populate[14]=dob.month&populate[15]=NewCForm1.FormStep1&populate[16]=NewCForm1.stepButtons&populate[17]=NewCForm1.Congratulations&populate[18]=NewCForm1.socials&populate[19]=NewCForm1.links`
    )
    .then((response) => {
      commit("GET_CREATOR_SIGNUP_PAGE", response.data.data.attributes);
    });
};
// CREATOR FAQ PAGE
export const getCreatorFaqPage = ({ commit }) => {
  axiosClient
    .get(
      `api/page-creator-faq?locale=${store.activeLanguage}&populate=*&pagination[page]=1&pagination[pageSize]=9999`
    )
    .then((response) => {
      commit("GET_CREATOR_FAQ_PAGE", response.data.data.attributes);
    });
};
// BLOG PAGE
export const getBlogPage = ({ commit }) => {
  axiosClient
    .get(`api/page-blog?locale=${store.activeLanguage}&populate[0]=Banner`)
    .then((response) => {
      commit("GET_BLOG_PAGE", response.data.data.attributes);
    });
};
// BLOG DETAILS PAGE
export const getBlogDetailsPage = ({ commit }) => {
  axiosClient
    .get(`api/page-blog-detail?locale=${store.activeLanguage}`)
    .then((response) => {
      commit("GET_BLOG_DETAILS_PAGE", response.data.data.attributes);
    });
};
// ===
export const getBrandFaq = ({ commit }) => {
  axiosClient
    .get(
      `api/brand-faqs?locale=${store.activeLanguage}&populate[1]=description&pagination[pageSize]=9999`
    )
    .then((response) => {
      commit("GET_Brand_FAQ", response.data.data);
    });
};
export const getCreatorFaq = ({ commit }) => {
  axiosClient
    .get(
      `api/creator-faqs?locale=${store.activeLanguage}&populate[1]=description&pagination[pageSize]=9999`
    )
    .then((response) => {
      commit("GET_Creator_FAQ", response.data.data);
    });
};

// TEAM
export const getTeamPage = ({ commit }) => {
  axiosClient
    .get(`api/team?locale=${store.activeLanguage}&populate=*`)
    .then((response) => {
      commit("GET_TEAM_PAGE", response.data.data.attributes);
    });
};

// DEPARTMENTS
export const getDepartments = ({ commit }) => {
  axiosClient
    .get(
      `/api/departments?locale=${store.activeLanguage}&populate=team_groups.image`
    )
    .then((response) => {
      if (
        typeof response.data.data !== "undefined" &&
        response.data.data.length !== 0
      ) {
        commit("GET_DEPARTMENTS", response.data.data);
        commit("SET_ACTIVE_DEPARTMENT", response.data.data[0].attributes.title);
      } else {
        commit("GET_DEPARTMENTS", null);
      }
    });
};

// =================== BLOG ====================
// ALL BLOGS Titles
export const getBlogTitles = ({ commit }) => {
  axiosClient
    .get(`/api/blog-topics?locale=${store.activeLanguage}`)
    .then((response) => {
      if (
        typeof response.data.data !== "undefined" &&
        response.data.data.length !== 0
      ) {
        commit("SET_ALL_BLOG_TITLES", response.data.data);
        commit("SET_ACTIVE_BLOG", response.data.data[0].id);
      } else {
        commit("SET_ALL_BLOG_TITLES", null);
      }
    });
};

export const updateActiveBlog = ({ commit }, blogId) => {
  commit("SET_ACTIVE_BLOG", blogId);
};
// Get Blogs
export const getBlogs = ({ commit }, { topicId, page, pageSize = 9 }) => {
  // if topic id is equal to 1 then show all posts
  if (parseInt(topicId) === 1) {
    axiosClient
      .get(
        `/api/blogs?locale=${store.activeLanguage}&sort[0]=Information.date:desc&populate[1]=Information&populate[2]=Image&populate[3]=blog_topics&pagination[page]=${page}&pagination[pageSize]=${pageSize}`
      )
      .then((response) => {
        if (
          typeof response.data.data !== "undefined" &&
          response.data.data.length !== 0
        ) {
          commit("SET_BLOGS", response.data.data);
          commit("SET_BLOGS_PAGINATION", response.data.meta.pagination);
        } else {
          commit("SET_BLOGS", null);
        }
      });
  } else {
    axiosClient
      .get(
        `/api/blogs?locale=${store.activeLanguage}&populate[1]=Information&populate[2]=Image&populate[3]=blog_topics&pagination[page]=${page}&pagination[pageSize]=9&filters[blog_topics][id][$in][0]=${topicId}`
      )
      .then((response) => {
        if (
          typeof response.data.data !== "undefined" &&
          response.data.data.length !== 0
        ) {
          commit("SET_BLOGS", response.data.data);
          commit("SET_BLOGS_PAGINATION", response.data.meta.pagination);
        }
        // else {
        //   commit("SET_BLOGS", null);
        //   commit("SET_BLOGS_PAGINATION", null);
        // }
      });
  }
};

// BLOG DETAILS
export const getBlogDetails = ({ commit }, id) => {
  // if topic id is equal to 1 then show all posts
  axiosClient
    .get(
      `/api/blogs/${id}?locale=${store.activeLanguage}&populate[1]=Information&populate[2]=Image&populate[3]=BlogParagraph&populate[4]=blog_topics `
    )
    .then((response) => {
      commit("SET_BLOG_DETAILS", response.data.data.attributes);
    });
};

export const getLocations = ({ commit }) => {
  axiosAPI.get(`v1/locations/fetch/all`).then((response) => {
    if (response.data && response.data.success) {
      let locations = response.data.data;
      commit("SET_LOCATIONS", locations);
    }
  });
};

export const setActiveJob = ({ commit }, data) => {
  commit("SET_ACTIVE_JOB", data);
};

export const getNationalities = ({ commit }) => {
  axiosAPI.get(`v1/locations/nationalities/fetch/all`).then((response) => {
    if (response.data && response.data.success) {
      let nationalities = response.data.data.countries;
      commit("SET_NATIONALITIES", nationalities);
    }
  });
};

export const getCountries = ({ commit }) => {
  axiosAPI.get(`v1/locations/countries/fetch/all`).then((response) => {
    if (response.data && response.data.success) {
      let countries = response.data.data.countries;
      commit("SET_COUNTRIES", countries);
    }
  });
};

export const getUTM = ({ commit }, utm) => {
  commit("SET_UTM", utm);
};

export const getGeneralSettings = ({ commit }, valueLoaded) => {
  axiosAPI.get(`v1/general-settings`).then((response) => {
    if (response.data) {
      commit("GET_GEN_SETTINGS", response.data);
      valueLoaded(response.data);
    }
  });
};
